import React, { useState, useEffect, useRef, useContext } from "react";
import Axios from "axios";
import { multiStepContext } from "../StepContext";
import { Button, DatePicker, Input, Modal, theme, Select } from "antd";
import "../Styles/ImportPage.css";
import * as XLSX from "xlsx";

const { RangePicker } = DatePicker;

const ReportDelivery = () => {
    const { admin } = useContext(multiStepContext);
    const [disableData, setDisableData] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const apiURL = process.env.REACT_APP_API_URL;
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [excelDateRange, setExcelDateRange] = useState(null);
    const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
    const [secondExcelDateRange, setSecondExcelDateRange] = useState(null);
    const fileInputRef = useRef(null);
    const [networks, setNetworks] = useState([]);
    const [selectedButton, setSelectedButton] = useState("disabled");
    const [packages, setPackages] = useState([]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const showSecondModal = () => {
        setIsSecondModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSecondCancel = () => {
        setIsSecondModalOpen(false);
    };

    const handleOk = () => {
        if (!excelDateRange || excelDateRange.length !== 2) {
            alert("กรุณาเลือกช่วงวันที่ก่อนดาวน์โหลดข้อมูล");
            return;
        }

        handleExcel();
        setIsModalOpen(false);
        setExcelDateRange(null);
    };

    const handleSecondOk = () => {
        if (!secondExcelDateRange || secondExcelDateRange.length !== 2) {
            alert("กรุณาเลือกช่วงวันที่ก่อนดาวน์โหลดข้อมูล");
            return;
        }

        handleSecondExcel();
        setIsSecondModalOpen(false);
        setSecondExcelDateRange(null);
    };

    const generateHeader = () => {
        return [
            "NO",
            "COMP_ORDER_ID",
            "INV_NO",
            "BARCODE_NO",
            "PRODUCT_IN_BOX",
            "SHIPPER_NAME",
            "SHIPPER_ADDRESS",
            "SHIPPER_AMPHUR",
            "SHIPPER_PROVINCE",
            "SHIPPER_ZIPCODE",
            "SHIPPER_TEL",
            "RECEIVER",
            "RECEIVER_ADDRESS",
            "RECEIVER_AMPHUR",
            "RECEIVER_PROVINCE",
            "RECEIVER_ZIPCODE",
            "RECEIVER_TEL",
            "WEIGHT",
            "PRICE",
            "INSURE",
            "INSURE_PRICE"
        ];
    };

    const generateHeaderSecond = () => {
        return [
            "รหัสการลงทะเบียน",
            "เลขบัตรประชาชน",
            "ชื่อ",
            "นามสกุล",
            "เครือข่าย",
            "ขอรับซิมการ์ดใหม่",
            "หมายเลขโทรศัพท์มือถือ",
            "รหัสสถานบริการ",
            "ชื่อสถานบริการที่จะเข้าไปรับซิมการ์ด"
        ];
    };

    const generateRow = (user, index) => {
        const runningNumber = (index + 1).toString();
        if (selectedButton === "disabled") {
            const userName = (user.type_id === 4 && user.guardian && user.guardian.firstname && user.guardian.lastname)
                ? user.guardian.firstname + " " + user.guardian.lastname
                : "";
            return [
                runningNumber,
                "",
                "",
                "",
                userName,
                "",
                "",
                "",
                "",
                "",
                "",
                user.firstName + " " + user.lastName,
                user.disabledInfo[0].address.addressName + " " + user.disabledInfo[0].address.subDistrict,
                user.disabledInfo[0].address.district,
                user.disabledInfo[0].address.province,
                user.disabledInfo[0].address.zipCode,
                user.disabledInfo[0].address.phoneNumber,
                "0",
                "0",
                "0",
                "0"
            ];
        } else if (selectedButton === "student") {
            return [
                runningNumber,
                "",
                "",
                "",
                user.studentId.p_firstname + " " + user.studentId.p_lastname,
                "",
                "",
                "",
                "",
                "",
                "",
                user.studentId.s_firstname + " " + user.studentId.s_lastname,
                user.studentInfo[0].address.addressName + " " + user.studentInfo[0].address.subDistrict,
                user.studentInfo[0].address.district,
                user.studentInfo[0].address.province,
                user.studentInfo[0].address.zipCode,
                user.studentInfo[0].address.phoneNumber,
                "0",
                "0",
                "0",
                "0"
            ];
        }
    };

    const generateRowSecond = (user) => {
        if (selectedButton === "disabled") {
            return [
                "D" + user.id,
                user.nationalId,
                user.firstName,
                user.lastName,
                user.disabledInfo[0].operators.company_name,
                user.purpose,
                user.disabledInfo[0].phoneNumber ? user.disabledInfo[0].phoneNumber : "",
                user.disabledInfo[0].branch_id,
                user.disabledInfo[0].branchs.name
            ];
        } else if (selectedButton === "student") {
            return [
                "S" + user.id,
                user.studentId.s_national_id,
                user.studentId.s_firstname,
                user.studentId.s_lastname,
                user.studentInfo[0].operators.company_name,
                user.purpose,
                user.studentInfo[0].phoneNumber ? user.studentInfo[0].phoneNumber : "",
                user.studentInfo[0].branch_id,
                user.studentInfo[0].branchs.name
            ];
        }
    };

    const handleExcel = () => {
        let filteredData;
        let excelName;
        let columnsToFormat = ["P", "Q"];
        const [startDate, endDate] = excelDateRange;
        console.log(startDate, endDate)

        if (selectedButton === "disabled") {
            excelName = "ผู้พิการ";
            filteredData = disableData.filter(user => {
                const confirmDate = new Date(user.disabledInfo[0].confirmDate);
                return confirmDate >= startDate && confirmDate <= endDate && user.disabledInfo[0].address;
            });
        } else if (selectedButton === "student") {
            excelName = "นักเรียน";
            filteredData = studentData.filter(user => {
                const confirmDate = new Date(user.studentInfo[0].confirmDate);
                return confirmDate >= startDate && confirmDate <= endDate && user.studentInfo[0].address;
            });
        }

        const ws1Data = [generateHeader()];
        filteredData.forEach((user, index) => {
            console.log(user)
            const row = generateRow(user, index);
            ws1Data.push(row);
        });

        const wb = XLSX.utils.book_new();
        const ws1 = XLSX.utils.aoa_to_sheet(ws1Data);
        const w1DataLength = ws1Data.length;
        XLSX.utils.book_append_sheet(wb, ws1, "Data");
        var fmt = "@";
        for (let i = 2; i <= w1DataLength; i++) {
            columnsToFormat.forEach((col) => {
                if (wb.Sheets["Data"][`${col}${i}`]) {
                    wb.Sheets["Data"][`${col}${i}`].z = fmt;
                }
            });
        }
        XLSX.writeFile(
            wb,
            `รายงานข้อมูลการจัดส่ง${excelName}.xlsx`
        );
    };

    const handleSecondExcel = () => {
        let filteredData;
        let excelName;
        let columnsToFormat = ["G", "H"];
        const [startDate, endDate] = secondExcelDateRange;

        if (selectedButton === "disabled") {
            excelName = "ผู้พิการ";
            filteredData = disableData.filter(user => {
                const confirmDate = new Date(user.disabledInfo[0].confirmDate);
                return confirmDate >= startDate && confirmDate <= endDate && user.disabledInfo[0].branchs;
            });
        } else if (selectedButton === "student") {
            excelName = "นักเรียน";
            filteredData = studentData.filter(user => {
                const confirmDate = new Date(user.studentInfo[0].confirmDate);
                return confirmDate >= startDate && confirmDate <= endDate && user.studentInfo[0].branchs;
            });
        }

        const ws1Data = [generateHeaderSecond()];
        filteredData.forEach((user) => {
            const row = generateRowSecond(user);
            ws1Data.push(row);
        });

        const wb = XLSX.utils.book_new();
        const ws1 = XLSX.utils.aoa_to_sheet(ws1Data);
        const w1DataLength = ws1Data.length;
        XLSX.utils.book_append_sheet(wb, ws1, "Data");
        var fmt = "@";
        for (let i = 2; i <= w1DataLength; i++) {
            columnsToFormat.forEach((col) => {
                if (wb.Sheets["Data"][`${col}${i}`]) {
                    wb.Sheets["Data"][`${col}${i}`].z = fmt;
                }
            });
        }
        XLSX.writeFile(
            wb,
            `รายงานข้อมูลการเข้ารับซิมการ์ดที่สถานบรืการ${excelName}.xlsx`
        );
    };

    const fetchData = async () => {
        try {
            const response = await Axios.get(`${apiURL}getDeliveryData`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            setDisableData(response.data.success.resultD);
            setStudentData(response.data.success.resultS);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        Axios.get(`${apiURL}getOperator`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                setNetworks(response.data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    useEffect(() => {
        fetchData();
    }, [token]);

    const clearFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    useEffect(() => {
        clearFileInput();
    }, [selectedButton]);

    useEffect(() => {
        const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        };

        Axios.get(`${apiURL}getPackage`, { headers })
            .then((res) => {
                const transformedData = res.data.map((item) => ({
                    packageId: item.id,
                    packageDetail: item.packageDetail,
                }));
                setPackages(transformedData);
            })
            .catch((err) => console.log(err));
    }, []);

    const handleButtonClick = (buttonId) => {
        setSelectedButton(buttonId);
    };

    return (
        <div className="import-outer">
            <div className="importContainer">
                <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    รายงานข้อมูลการจัดส่งซิมการ์ด
                </span>
            </div>
            <div className="info-search">
                <div className="type-bt">
                    <Button
                        id="disabled"
                        onClick={() => handleButtonClick("disabled")}
                        type={selectedButton === "disabled" ? "primary" : "default"}
                        danger
                        size="large"
                    >
                        คนพิการ
                    </Button>
                    <Button
                        id="student"
                        onClick={() => handleButtonClick("student")}
                        type={selectedButton === "student" ? "primary" : "default"}
                        danger
                        size="large"
                    >
                        นักเรียน
                    </Button>
                </div>
            </div>
            <div>
                {/* First Button and Modal */}
                <Button onClick={showModal} type="primary">ดาวน์โหลดข้อมูลที่อยู่การจัดส่งซิมการ์ด</Button>
                <Modal
                    title="ข้อมูลที่อยู่การจัดส่งซิมการ์ด"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                >
                    <div>
                        <span style={{ marginRight: 10 }}>วันที่ข้อมูล:</span>
                        <RangePicker
                            onChange={(dates) => {
                                if (dates && dates.length === 2) {
                                    const startDate = dates[0].startOf('day'); // 00:00:00
                                    const endDate = dates[1].endOf('day'); // 23:59:59
                                    setExcelDateRange([startDate, endDate]);
                                } else {
                                    setExcelDateRange(null);
                                }
                            }}
                            value={excelDateRange}
                        />
                    </div>
                </Modal>
            </div>
            <div>
                <Button onClick={showSecondModal} type="primary">ดาวน์โหลดข้อมูลสาขาการเข้ารับซิมการ์ด</Button>
                <Modal
                    title="ข้อมูลสาขาการเข้ารับซิมการ์ด"
                    open={isSecondModalOpen}
                    onOk={handleSecondOk}
                    onCancel={handleSecondCancel}
                >
                    <div>
                        <span style={{ marginRight: 10 }}>วันที่ข้อมูล:</span>
                        <RangePicker
                            onChange={(dates) => {
                                if (dates && dates.length === 2) {
                                    const startDate = dates[0].startOf('day'); // 00:00:00
                                    const endDate = dates[1].endOf('day'); // 23:59:59
                                    setSecondExcelDateRange([startDate, endDate]);
                                } else {
                                    setSecondExcelDateRange(null);
                                }
                            }}
                            value={secondExcelDateRange}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default ReportDelivery;