import React, { useState, useEffect, useRef, useContext } from "react";
import { RedoOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Modal, theme, Select } from "antd";
import * as XLSX from "xlsx";
import "jspdf-autotable";
import { useReactToPrint } from "react-to-print";
import "../Styles/ManagePage.css";
import Axios from "axios";
import { multiStepContext } from "../StepContext";
const apiURL = process.env.REACT_APP_API_URL;
const { Search } = Input;
const { RangePicker } = DatePicker;
const Report = () => {
  const [currentData, setCurrentData] = useState([]);
  const [selectedButton, setSelectedButton] = useState("disable");
  const [dateRange, setDateRange] = useState(null);
  const [excelDateRange, setExcelDateRange] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const { token: { colorBgContainer, borderRadiusLG }, } = theme.useToken();
  const [disableData, setDisableData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState('all')
  const [passStatus, setPassStatus] = useState('all')

  // test token
  const { admin } = useContext(multiStepContext);
  const token = localStorage.getItem("token");

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    handleExcel();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const setFilterStatusChange = (value) => {
    setFilterStatus(value)
  }

  const setPassStatusChange = (value) => {
    setPassStatus(value)
  }

  const handlePublishOk = () => {
    setIsPublishModalOpen(false);
    console.log(selectedCheckboxes);
    if (selectedCheckboxes.length === 0) {
      return;
    }
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    if (selectedButton === "disable") {
      const dataToSend = selectedCheckboxes.map((checkbox) => ({
        id: checkbox.id,
        type: "disable",
      }));
      Axios.put(`${apiURL}public/disabled`, dataToSend, { headers })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else if (selectedButton === "student") {
      const dataToSend = selectedCheckboxes.map((checkbox) => ({
        id: checkbox.id,
        type: "student",
      }));
      console.log("data to send", dataToSend);
      Axios.put(`${apiURL}public/student`, dataToSend, { headers })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    setIsChecked(false);
    setSelectedCheckboxes([]);
  };

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    Axios.get(`${apiURL}getAllDisabled`, { headers })
      .then((res) => {
        setDisableData(res.data.result);
        setCurrentData(res.data.result);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    Axios.get(`${apiURL}getAllStudent`, { headers })
      .then((res) => {
        setStudentData(res.data.result);
        setCurrentData(res.data.result);
      })
      .catch((err) => console.log(err));
  }, []);
  //Table

  const showDisableData = () => {
    let filteredData;
    if (!Array.isArray(disableData) || !Array.isArray(studentData)) {
      return <div>No data available</div>;
    }
    if (selectedButton === "disable") {
      filteredData = disableData.filter((user) => {
        const nationalId =
          user && user.nationalId ? user.nationalId.toString() : "";
        const matchesSearch = nationalId
          .toLowerCase()
          .includes(searchValue.toLowerCase());
        const createDate =
          user.disabledInfo &&
          user.disabledInfo[0] &&
          user.disabledInfo[0].createDate;
        const userDate = createDate ? new Date(createDate) : null;
        const withinDateRange = dateRange
          ? (!dateRange[0] || userDate >= dateRange[0]) &&
          (!dateRange[1] ||
            userDate <= new Date(dateRange[1]).getTime() + 86400000)
          : true;
        return matchesSearch && withinDateRange;
      });
      return (
        <div>
          <div className="inner-con">
            <table className="table-with-gap">
              <thead>
                <tr>
                  <th>เลขบัตรประชาชน</th>
                  <th>ชื่อ</th>
                  <th>นามสกุล</th>
                  <th>หมายเลขโทรศัพท์มือถือ</th>
                  <th>เครือข่าย</th>
                  <th>ขอรับซิมการ์ดใหม่</th>
                  <th>สถานะ</th>
                  <th>วันที่ได้ข้อมูล</th>
                  <th>ผลการอนุมัติ</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((user, index) => (
                  <tr key={index}>
                    <td>{user.nationalId}</td>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>
                      {user.disabledInfo &&
                        user.disabledInfo[0] &&
                        user.disabledInfo[0].phoneNumber}
                    </td>
                    <td>
                      {user.disabledInfo &&
                        user.disabledInfo[0] &&
                        user.disabledInfo[0].operators &&
                        user.disabledInfo[0].operators.company_name}
                    </td>
                    <td>{user.purpose}</td>
                    <td>{user.status}</td>
                    <td>
                      {user.disabledInfo &&
                        user.disabledInfo[0] &&
                        user.disabledInfo[0].createDate &&
                        (() => {
                          const createDate = user.disabledInfo[0].createDate;
                          const dateWithoutTime = createDate.split("T")[0];
                          const [year, month, day] = dateWithoutTime.split("-");
                          const formattedDate = `${day}/${month}/${year}`;
                          return formattedDate;
                        })()}
                    </td>
                    <td>
                      {user.approve_status ? user.approve_status : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else if (selectedButton === "student") {
      filteredData = studentData.filter((user) => {
        const studentNationalId =
          user && user.studentId && user.studentId.s_national_id
            ? user.studentId.s_national_id.toString()
            : "";
        const matchesSearch = studentNationalId
          .toLowerCase()
          .includes(searchValue.toLowerCase());
        const createDate =
          user.studentInfo &&
          user.studentInfo[0] &&
          user.studentInfo[0].createDate;
        const userDate = createDate ? new Date(createDate) : null;
        const withinDateRange = dateRange
          ? (!dateRange[0] || userDate >= dateRange[0]) &&
          (!dateRange[1] ||
            userDate <= new Date(dateRange[1]).getTime() + 86400000)
          : true;
        return matchesSearch && withinDateRange;
      });
      return (
        <div className="container">
          <div className="inner-con">
            <table className="table-with-gap">
              <thead className="col-header">
                <tr>
                  <th>เลขบัตรประชาชน</th>
                  <th>ชื่อ</th>
                  <th>นามสกุล</th>
                  <th>หมายเลขโทรศัพท์มือถือ</th>
                  <th>ระดับชั้น</th>
                  <th>เครือข่าย</th>
                  <th>ขอรับซิมการ์ดใหม่</th>
                  <th>สถานะ</th>
                  <th>วันที่ได้ข้อมูล</th>
                  <th>ผลการอนุมัติ</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((user, index) => (
                  <tr key={index}>
                    <td>{user.studentId.s_national_id}</td>
                    <td>{user.studentId && user.studentId.s_firstname}</td>
                    <td>{user.studentId && user.studentId.s_lastname}</td>
                    <td>
                      {user.studentInfo &&
                        user.studentInfo[0] &&
                        user.studentInfo[0].phoneNumber}
                    </td>
                    <td>{user.studentId && user.studentId.degrees}</td>
                    <td>
                      {user.studentInfo &&
                        user.studentInfo[0] &&
                        user.studentInfo[0].operators &&
                        user.studentInfo[0].operators.company_name}
                    </td>
                    <td>{user.purpose}</td>
                    <td>{user.status}</td>
                    <td>
                      {user.studentInfo &&
                        user.studentInfo[0] &&
                        user.studentInfo[0].createDate &&
                        (() => {
                          const createDate = user.studentInfo[0].createDate;
                          const dateWithoutTime = createDate.split("T")[0];
                          const [year, month, day] = dateWithoutTime.split("-");
                          const formattedDate = `${day}/${month}/${year}`;
                          return formattedDate;
                        })()}
                    </td>
                    <td>
                      {user.approve_status ? user.approve_status : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return <div>No data available</div>;
    }
  };

  //SET button color
  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
    setCurrentData(buttonId === "disable" ? disableData : studentData);
    setSelectedCheckboxes([]);
  };
  const [sheetData, setSheetData] = useState(null);

  useEffect(() => {
    if (selectedButton === "disable") {
      setSheetData(currentData);
    } else if (selectedButton === "student") {
      setSheetData(currentData);
    }
  }, [selectedButton]);

  const generateHeader = () => {
    if (selectedButton === "disable") {
      return [
        "รหัสการลงทะเบียน",
        "เลขบัตรประชาชน",
        "ชื่อ",
        "นามสกุล",
        "เครือข่าย",
        "ขอรับซิมการ์ดใหม่",
        "หมายเลขโทรศัพท์มือถือ",
        "วันที่ได้ข้อมูล",
        "สถานะ",
        "ผลการอนุมัติ (ผ่าน/ไม่ผ่าน)",
        "เหตุผล (กรณีไม่ผ่าน)",
        "เบอร์โทรติดต่อ",
        "อีเมล",
        "Line ID"
      ];
    } else if (selectedButton === "student") {
      return [
        "รหัสการลงทะเบียน",
        "เลขบัตรประชาชน",
        "ชื่อ",
        "นามสกุล",
        "ระดับชั้น",
        "เครือข่าย",
        "ขอรับซิมการ์ดใหม่",
        "หมายเลขโทรศัพท์มือถือ",
        "วันที่ได้ข้อมูล",
        "สถานะ",
        "ผลการอนุมัติ (ผ่าน/ไม่ผ่าน)",
        "เหตุผล (กรณีไม่ผ่าน)",
        "เบอร์โทรติดต่อ",
        "อีเมล",
        "Line ID"
      ];
    }
  };

  const generateRow = (user) => {
    if (selectedButton === "disable") {
      return [
        "D" + user.id,
        user.nationalId,
        user.firstName,
        user.lastName,
        user.disabledInfo[0].operators.company_name,
        user.purpose,
        user.disabledInfo[0].phoneNumber ? user.disabledInfo[0].phoneNumber : "",
        user.disabledInfo[0].createDate
          ? (() => {
            const createDate = user.disabledInfo[0].createDate;
            const dateWithoutTime = createDate.split("T")[0];
            const [year, month, day] = dateWithoutTime.split("-");
            return `${day}/${month}/${year}`;
          })()
          : "",
        user.status,
        user.approve_status ? user.approve_status : "",
        user.approve_reason ? user.approve_reason : "",
        user.disabledInfo[0].address && user.disabledInfo[0].address.phoneNumber ? user.disabledInfo[0].address.phoneNumber : "",
        user.disabledInfo[0].address && user.disabledInfo[0].address.email ? user.disabledInfo[0].address.email : "",
        user.disabledInfo[0].address && user.disabledInfo[0].address.lineID ? user.disabledInfo[0].address.lineID : ""
      ];
    } else if (selectedButton === "student") {
      return [
        "S" + user.id,
        user.studentId.s_national_id,
        user.studentId.s_firstname,
        user.studentId.s_lastname,
        user.studentId.degrees,
        user.studentInfo[0].operators.company_name,
        user.purpose,
        user.studentInfo[0].phoneNumber ? user.studentInfo[0].phoneNumber : "",
        user.studentInfo[0].createDate
          ? (() => {
            const createDate = user.studentInfo[0].createDate;
            const dateWithoutTime = createDate.split("T")[0];
            const [year, month, day] = dateWithoutTime.split("-");
            return `${day}/${month}/${year}`;
          })()
          : "",
        user.status,
        user.approve_status ? user.approve_status : "",
        user.approve_reason ? user.approve_reason : "",
        user.studentInfo[0].address && user.studentInfo[0].address.phoneNumber ? user.studentInfo[0].address.phoneNumber : "",
        user.studentInfo[0].address && user.studentInfo[0].address.email ? user.studentInfo[0].address.email : "",
        user.studentInfo[0].address && user.studentInfo[0].address.lineID ? user.studentInfo[0].address.lineID : ""

      ];
    }
  };
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    Axios.get(`${apiURL}getPackage`, { headers })
      .then((res) => {
        console.log("Packages from API:", res.data);
        const transformedData = res.data.map((item) => ({
          packageId: item.id,
          packageName: item.packageName,
        }));
        setPackages(transformedData);
        console.log(transformedData);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleExcel = () => {
    let filteredData;
    let packagesData;
    let columnsToFormat;
    let excelName;

    if (selectedButton === "disable") {
      excelName = "ข้อมูลผู้พิการ";
      columnsToFormat = ["G", "L"];
      filteredData = disableData;
    } else if (selectedButton === "student") {
      excelName = "ข้อมูลนักเรียน";
      columnsToFormat = ["H", "M"];
      filteredData = studentData;
    } else {
      return;
    }
    const ws1Data = [generateHeader()];
    filteredData.forEach((user) => {
      const row = generateRow(user);
      ws1Data.push(row);
    });

    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.aoa_to_sheet(ws1Data);
    const w1DataLength = ws1Data.length;
    console.log(w1DataLength);
    XLSX.utils.book_append_sheet(wb, ws1, "Data");
    var fmt = "@";
    for (let i = 2; i <= w1DataLength; i++) {
      columnsToFormat.forEach((col) => {
        if (wb.Sheets["Data"][`${col}${i}`]) {
          wb.Sheets["Data"][`${col}${i}`].z = fmt;
        }
      });
    }
    XLSX.writeFile(
      wb,
      `รายงาน${excelName}ทั้งหมด.xlsx`
    );
  };
  //Export PDF
  const componentPDF = useRef();
  const handlePDF = useReactToPrint({
    content: () => componentPDF.current,
  });
  //EXPORT PRINT
  const handlePrint = useReactToPrint({
    content: () => componentPDF.current,
  });
  const fileInputRef = useRef(null);
  const clearSearch = () => {
    setSearchValue("");
  };
  useEffect(() => {
    clearSearch();
  }, selectedButton);
  const clearDate = () => {
    setDateRange(null);
    setSearchValue("");
  };
  const [disableFile, setDisableFile] = useState([]);
  const [studentFile, setStudentFile] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  useEffect(() => {
    Axios.get(`${apiURL}upload/showDisabled`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setDisableFile(res.data.success);
      console.log(res);
    });
  }, []);
  useEffect(() => {
    Axios.get(`${apiURL}upload/showStudent`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setStudentFile(res.data.success);
      console.log(res);
    });
  }, []);
  const [isChecked, setIsChecked] = useState(false);
  return (
    <div className="client-header-con">
      <div className="client-header">
        <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
          ดาวน์โหลดข้อมูลทั้งหมด
        </span>
      </div>
      <div className="client-type">
        <div className="type-bt">
          <Button
            id="disable"
            onClick={() => handleButtonClick("disable")}
            type={selectedButton === "disable" ? `primary` : "default"}
            danger
            size="large"
          >
            คนพิการ
          </Button>
          <Button
            id="student"
            onClick={() => handleButtonClick("student")}
            type={selectedButton === "student" ? "primary" : "default"}
            danger
            size="large"
          >
            นักเรียน
          </Button>
        </div>
      </div>
      <div>
        <Button type="primary" onClick={() => handleOk()}> ดาวน์โหลดข้อมูลทั้งหมด</Button>
      </div>
    </div>
  );
};
export default Report;
